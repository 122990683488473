import { updateObjectWithDelete } from "../lib/updateObjectWithDelete";
import { Template } from "../types/template";
import { TypeKcc20zHWrk6eqylThP2t } from "../types/template/Kcc20zHWrk6eqylThP2t";
import { ImageUploader } from "../ui/imageUploader";
import { SubStep } from "../ui/subStep";
import { SubStepTitle } from "../ui/subStepTitle";
import { SubTitle } from "../ui/subTitle";
import { TextInput } from "../ui/textInput";

type Props = {
  setTemplate: React.Dispatch<
    React.SetStateAction<Template & TypeKcc20zHWrk6eqylThP2t>
  >;
  convertImage: (image: ArrayBuffer) => Promise<string>;
};

export const Kcc20zHWrk6eqylThP2t: React.FC<Props> = ({
  setTemplate,
  convertImage,
}) => {
  return (
    <>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="1. ロゴ画像をアップロード" />
        </SubStepTitle>
        <ImageUploader
          setLink={(image: ArrayBuffer) =>
            convertImage(image).then((link: string) => {
              setTemplate((prev: Template & TypeKcc20zHWrk6eqylThP2t) => ({
                ...prev,
                logo: link,
              }));
            })
          }
        />
      </SubStep>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="2. 差出人・返還先" />
        </SubStepTitle>
        <TextInput
          title="郵便番号"
          onChange={(value) => {
            setTemplate((prev: Template & TypeKcc20zHWrk6eqylThP2t) => ({
              ...prev,
              postal_code: value,
            }));
          }}
        />
        <TextInput
          title="住所 (1行目)"
          onChange={(value) => {
            setTemplate((prev: Template & TypeKcc20zHWrk6eqylThP2t) => ({
              ...prev,
              address1: value,
            }));
          }}
        />
        <TextInput
          title="住所 (2行目)"
          onChange={(value) => {
            setTemplate((prev: any) => {
              return updateObjectWithDelete(prev, "address2", value);
            });
          }}
        />
        <TextInput
          title="住所 (3行目)"
          onChange={(value) => {
            setTemplate((prev: any) => {
              return updateObjectWithDelete(prev, "address3", value);
            });
          }}
        />
      </SubStep>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="3. QRコードのリンク先" />
        </SubStepTitle>
        <TextInput
          onChange={(value) => {
            setTemplate((prev: Template & TypeKcc20zHWrk6eqylThP2t) => ({
              ...prev,
              link: value,
            }));
          }}
        />
      </SubStep>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="4. テンプレート名" />
        </SubStepTitle>
        <TextInput
          onChange={(value) => {
            setTemplate((prev: Template & TypeKcc20zHWrk6eqylThP2t) => ({
              ...prev,
              title: value,
            }));
          }}
        />
      </SubStep>
    </>
  );
};
