import axios from "axios";
import { Buffer } from "buffer";

export interface ICloudinaryRepository {
  createAsset(image: ArrayBuffer, shop: string): Promise<string | null>;
}

export class CloudinaryRepository implements ICloudinaryRepository {
  async createAsset(image: ArrayBuffer, shop: string): Promise<string | null> {
    return axios
      .post(
        `${process.env.REACT_APP_API as string}/cloudinary/stores/${shop}`,
        { image: Buffer.from(new Uint8Array(image)).toString("hex") },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data.link as string;
      })
      .catch((err) => {
        console.error(`CloudinaryRepository.createAsset: ${err}`);
        return null;
      });
  }
}
