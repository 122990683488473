import axios from "axios";
import { Layout } from "../../types/layout";

export interface ILayoutRepository {
  listLayouts(sheetId: string): Promise<Layout[]>;
}

export class LayoutRepository implements ILayoutRepository {
  async listLayouts(sheetId: string): Promise<Layout[]> {
    return axios
      .get(`${process.env.REACT_APP_API as string}/sheets/${sheetId}/layouts`)
      .then((res) => {
        return res.data.layouts as Layout[];
      })
      .catch((err) => {
        console.error(`LayoutRepository.listLayouts: ${err}`);
        return [] as Layout[];
      });
  }
}
