import axios from "axios";
import { Template } from "../../types/template";

export interface ITemplateRepository {
  listTemplates(shop: string): Promise<Template[]>;
  createTemplate(template: Template): Promise<string | null>;
}

export class TemplateRepository implements ITemplateRepository {
  async listTemplates(shop: string): Promise<Template[]> {
    return axios
      .get(`${process.env.REACT_APP_API as string}/templates?shop=${shop}`)
      .then((res) => {
        return res.data.templates as Template[];
      })
      .catch((err) => {
        console.error(`TemplateRepository.listTemplates: ${err}`);
        return [];
      });
  }

  async createTemplate(template: Template): Promise<string | null> {
    return axios
      .post(`${process.env.REACT_APP_API as string}/templates`, template)
      .then((res) => {
        return res.data.id as string;
      })
      .catch((err) => {
        console.error(`TemplateRepository.createTemplate: ${err}`);
        return null;
      });
  }
}
