import styled from "styled-components";
import { colors } from "../../lib/colors";
import { fonts } from "../../styles/fonts";

type Props = {
  number: string;
};

export const NumberLabel: React.FC<Props> = ({ number }) => {
  return <NumberLabelComponent>{number}</NumberLabelComponent>;
};

const NumberLabelComponent = styled.div`
  ${fonts.title};
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-right: 6px;
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
`;
