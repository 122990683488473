import styled from "styled-components";
import { fonts } from "../../styles/fonts";

type Props = {
  description: string;
};

export const Description: React.FC<Props> = ({ description }) => {
  return <DescriptionComponent>{description}</DescriptionComponent>;
};

const DescriptionComponent = styled.p`
  ${fonts.description};
  text-align: center;
  width: 100%;
`;
