import { Template } from "../types/template";
import { TypeAHhQHBCyHCoNjouPELeu } from "../types/template/AHhQHBCyHCoNjouPELeu";
import { SubStep } from "../ui/subStep";
import { SubStepTitle } from "../ui/subStepTitle";
import { SubTitle } from "../ui/subTitle";
import { TextArea } from "../ui/textArea";
import { TextInput } from "../ui/textInput";

type Props = {
  setTemplate: React.Dispatch<
    React.SetStateAction<Template & TypeAHhQHBCyHCoNjouPELeu>
  >;
};

export const AHhQHBCyHCoNjouPELeu: React.FC<Props> = ({ setTemplate }) => {
  return (
    <>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="1. 依頼主" />
        </SubStepTitle>
        <TextInput
          title="依頼主名"
          placeholder="SOYEES配送センター"
          onChange={(value) => {
            setTemplate((prev: Template & TypeAHhQHBCyHCoNjouPELeu) => ({
              ...prev,
              shipping_from: value,
            }));
          }}
        />
        <TextInput
          title="郵便番号"
          placeholder="150-0022"
          onChange={(value) => {
            setTemplate((prev: Template & TypeAHhQHBCyHCoNjouPELeu) => ({
              ...prev,
              postal_code: value,
            }));
          }}
        />
        <TextInput
          title="住所"
          onChange={(value) => {
            setTemplate((prev: Template & TypeAHhQHBCyHCoNjouPELeu) => ({
              ...prev,
              address: value,
            }));
          }}
        />
      </SubStep>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="2. 品名" />
        </SubStepTitle>
        <TextInput
          onChange={(value) => {
            setTemplate((prev: Template & TypeAHhQHBCyHCoNjouPELeu) => ({
              ...prev,
              item: value,
            }));
          }}
        />
      </SubStep>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="3. 配達員様へ" />
        </SubStepTitle>
        <TextArea
          onChange={(value) => {
            setTemplate((prev: Template & TypeAHhQHBCyHCoNjouPELeu) => ({
              ...prev,
              message: value,
            }));
          }}
        />
      </SubStep>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="4. テンプレート名 (管理用)" />
        </SubStepTitle>
        <TextInput
          onChange={(value) => {
            setTemplate((prev: Template & TypeAHhQHBCyHCoNjouPELeu) => ({
              ...prev,
              title: value,
            }));
          }}
        />
      </SubStep>
    </>
  );
};
