import axios from "axios";
import { Order } from "../../types/order";
import { Sheet } from "../../types/sheet";

export interface IShopifyRepository {
  applyTag(
    shop: string,
    tagName: string,
    orderIds: string[]
  ): Promise<"success" | "error">;
  getOrders(shop: string, orderIds: string[]): Promise<Order[] | null>;
}

export class ShopifyRepository implements IShopifyRepository {
  async applyTag(
    shop: string,
    tagName: string,
    orderIds: string[]
  ): Promise<"success" | "error"> {
    return axios
      .post(
        `${process.env.REACT_APP_API as string}/shopify/applyTag?shop=${shop}`,
        {
          tag_name: tagName,
          order_ids: orderIds,
        }
      )
      .then(() => {
        return "success" as const;
      })
      .catch((err) => {
        console.error(`ShopifyRepository.applyTag: ${err}`);
        return "error" as const;
      });
  }

  async getOrders(shop: string, orderIds: string[]): Promise<Order[] | null> {
    return axios
      .get(
        `${
          process.env.REACT_APP_API as string
        }/shopify/orders?shop=${shop}&orderIds=${encodeURI(
          JSON.stringify(orderIds)
        )}`
      )
      .then((res) => {
        return res.data.orders as Order[];
      })
      .catch((err) => {
        console.error(`ShopifyRepository.getOrders: ${err}`);
        return null;
      });
  }
}
