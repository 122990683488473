import styled from "styled-components";
import { colors } from "../../lib/colors";

import { ReactNode } from "react";

type Props = {
  selected: boolean;
  setSelected: () => void;
  children: ReactNode;
};

export const RadioItemHorizontal: React.FC<Props> = ({
  selected,
  setSelected,
  children,
}) => {
  return (
    <>
      {selected ? (
        <RadioItemHorizontalComponentSelected>
          {children}
        </RadioItemHorizontalComponentSelected>
      ) : (
        <RadioItemHorizontalComponent onClick={setSelected}>
          {children}
        </RadioItemHorizontalComponent>
      )}
    </>
  );
};

const RadioItemHorizontalComponent = styled.div`
  background-color: ${colors.white};
  border-radius: 4px;
  cursor: pointer;
`;

const RadioItemHorizontalComponentSelected = styled(
  RadioItemHorizontalComponent
)`
  border: solid 1px ${colors.primary};
`;
