import axios from "axios";
import { Sheet } from "../../types/sheet";

export interface ISheetRepository {
  listAllSheets(): Promise<Sheet[]>;
}

export class SheetRepository implements ISheetRepository {
  async listAllSheets(): Promise<Sheet[]> {
    return axios
      .get(`${process.env.REACT_APP_API as string}/sheets`)
      .then((res) => {
        return res.data.sheets as Sheet[];
      })
      .catch((err) => {
        console.error(`SheetRepository.listAllSheets: ${err}`);
        return [] as Sheet[];
      });
  }
}
