import styled from "styled-components";
import { fonts } from "../../styles/fonts";

type Props = {
  title: string;
};

export const ItemTitle: React.FC<Props> = ({ title }) => {
  return <ItemTitleComponent>{title}</ItemTitleComponent>;
};

const ItemTitleComponent = styled.h3`
  ${fonts.itemTitle}
`;
