import { ITemplateRepository } from "../../repositories/template";
import { Template } from "../../types/template";

export interface ITemplateUsecase {
  listTemplates(shop: string): Promise<Template[]>;
  createTemplate(template: Template): Promise<string | null>;
}

export class TemplateUsecase implements ITemplateUsecase {
  constructor(private templateRepository: ITemplateRepository) {}

  async listTemplates(shop: string): Promise<Template[]> {
    return this.templateRepository.listTemplates(shop);
  }

  async createTemplate(template: Template): Promise<string | null> {
    return this.templateRepository.createTemplate(template);
  }
}
