import { useEffect } from "react";
import { IStoreUsecase } from "../../usecases/store";

export interface IStorePage {
  oauth: React.FC;
}

export class StorePage implements IStorePage {
  constructor(private storeUsecase: IStoreUsecase) {}

  oauth: React.FC = () => {
    const params = new URLSearchParams(window.location.search);
    const shop = params.get("shop") ?? "";
    const code = params.get("code") ?? "";

    useEffect(() => {
      this.storeUsecase.setAccessToken(shop, code).then((data) => {
        if (!data) {
          console.error(`StorePage.oauth: access token is not set`);
          return;
        }
        window.location.href = `https://admin.shopify.com/store/${shop.replace(
          ".myshopify.com",
          ""
        )}/apps/${data.client_id}`;
      });
    }, []);

    return <></>;
  };
}
