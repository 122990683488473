import styled from "styled-components";
import { fonts } from "../../styles/fonts";

type Props = {
  setLink: (image: ArrayBuffer) => void;
};

export const ImageUploader: React.FC<Props> = ({ setLink }) => {
  return (
    <ImageUploaderComponent>
      <ImageInput
        type="file"
        accept="image/*"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            const fr = new FileReader();
            fr.onload = () => {
              if (fr.result && typeof fr.result !== "string") {
                setLink(fr.result);
              }
            };
            fr.readAsArrayBuffer(e.target.files[0]);
          }
        }}
      />
    </ImageUploaderComponent>
  );
};

const ImageUploaderComponent = styled.div`
  margin: 0 0 8px;
`;
const ImageInput = styled.input`
  ${fonts.itemTitle};
  height: 32px;
  width: 280px;
`;
