import styled from "styled-components";
import { colors } from "../../lib/colors";

import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const Collection: React.FC<Props> = (props) => {
  return <CollectionComponent>{props.children}</CollectionComponent>;
};

const CollectionComponent = styled.div`
  padding: 16px 24px;
  background-color: ${colors.gray};
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  border-radius: 2px;
`;
