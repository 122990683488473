import axios from "axios";
import { Store } from "../../types/store";

export interface IStoreRepository {
  getStore(
    shop: string
  ): Promise<{ store: Store; access_scopes: string[] } | null>;
  setAccessToken(shop: string, code: string): Promise<Store | null>;
  updateStore(store: Store): Promise<Store | null>;
  updateDefaultTemplate(shop: string, template: string): Promise<Store | null>;
}

export class StoreRepository implements IStoreRepository {
  async getStore(
    shop: string
  ): Promise<{ store: Store; access_scopes: string[] } | null> {
    return axios
      .get(`${process.env.REACT_APP_API as string}/stores/${shop}`)
      .then((res) => {
        return res.data as { store: Store; access_scopes: string[] };
      })
      .catch((err) => {
        console.error(`StoreRepository.getStore: ${err}`);
        return null;
      });
  }

  async setAccessToken(shop: string, code: string): Promise<Store | null> {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API as string
        }/setAccessToken?shop=${shop}&code=${code}`
      )
      .then((res) => {
        return res.data as Store;
      })
      .catch((err) => {
        console.error(`StoreRepository.setAccessToken: ${err}`);
        return null;
      });
  }

  async updateStore(store: Store): Promise<Store | null> {
    return axios
      .put(`${process.env.REACT_APP_API as string}/stores/${store.shop}`, {
        ...store,
        horizontal_position: parseFloat(store.horizontal_position),
        vertical_position: parseFloat(store.vertical_position),
        horizontal_scale: parseFloat(store.horizontal_scale),
        vertical_scale: parseFloat(store.vertical_scale),
      })
      .then((_res) => {
        return store;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  async updateDefaultTemplate(
    shop: string,
    template: string
  ): Promise<Store | null> {
    return axios
      .patch(
        `${
          process.env.REACT_APP_API as string
        }/stores/default-template?shop=${shop}&template=${template}`
      )
      .then((res) => {
        return res.data as Store;
      })
      .catch((err) => {
        console.error(`StoreRepository.updateDefaultTemplate: ${err}`);
        return null;
      });
  }
}
