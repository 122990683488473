import { forwardRef } from "react";
import QRCode from "qrcode.react";
import { Order } from "../../types/order";
import "./style.scss";
import { chunkArr } from "../../lib/chunkArr";

type Props = {
  orders: (Order & { linkId: string })[];
  template: any;
  verticalPosition: number;
  verticalScale: number;
};

export const PrintKcc20zHWrk6eqylThP2t = forwardRef<HTMLDivElement, Props>(
  ({ orders, template, verticalPosition, verticalScale }, ref) => {
    return (
      <div ref={ref} className="pages">
        {chunkArr(orders, 27).map((chunkedOrders, index) => {
          return (
            <div
              className="labels"
              key={index}
              style={{
                transform: `scale(1, ${verticalScale})`,
                transformOrigin: `${verticalPosition}px left`,
              }}
            >
              {chunkedOrders.map((order, index) => {
                return (
                  <div className="labels__label" key={index}>
                    <div className="labels__label__shippingAddress">
                      〒{order.customer.zip}
                      <br />
                      {order.customer.province}
                      {order.customer.city}
                      <br />
                      {order.customer.address1}
                      {order.customer.address2 ? (
                        <>
                          <br />
                          {order.customer.address2}
                        </>
                      ) : null}
                      <br />
                      {order.customer.lastName} {order.customer.firstName} 様
                    </div>
                    <div className="labels__label__returnAddress">
                      【差出人・返送先】〒{template.postal_code ?? ""}
                      <br />
                      {template.address1 ?? ""}
                      {template.address2 ? (
                        <>
                          <br />
                          {template.address2}
                        </>
                      ) : null}
                      {template.address3 ? (
                        <>
                          <br />
                          {template.address3}
                        </>
                      ) : null}
                    </div>
                    <div className="labels__label__logo">
                      <img src={template.logo} alt="logo" />
                    </div>
                    <div className="labels__label__qr">
                      <QRCode
                        value={template.link}
                        size={46}
                        renderAs="svg"
                        level="L"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
);
