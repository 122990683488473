import styled from "styled-components";
import { colors } from "../../lib/colors";

type Props = {
  src: string;
};

export const ItemImage: React.FC<Props> = ({ src }) => {
  return <ItemImageComponent src={src} />;
};

const ItemImageComponent = styled.img`
  width: 100%;
  margin: 0 auto;
  display: block;
  border: solid 1px ${colors.gray};
`;
