import { forwardRef } from "react";
import QRCode from "qrcode.react";
import { Order } from "../../types/order";
import "./style.scss";
import { chunkArr } from "../../lib/chunkArr";
import { Template } from "../../types/template";
import { TypeHYGZJnNu9m6rnHRIBe9b } from "../../types/template/hYGZJnNu9m6rnHRIBe9b";

type Props = {
  orders: (Order & { linkId: string })[];
  template: Template & TypeHYGZJnNu9m6rnHRIBe9b;
  verticalPosition: number;
  verticalScale: number;
};

export const PrintHYGZJnNu9m6rnHRIBe9b = forwardRef<HTMLDivElement, Props>(
  ({ orders, template, verticalPosition, verticalScale }, ref) => {
    return (
      <div
        ref={ref}
        className="pages"
        style={{
          transform: `scale(1, ${verticalScale})`,
          transformOrigin: `${verticalPosition}px left`,
        }}
      >
        {chunkArr(orders, 27).map((chunkedOrders, index) => {
          return (
            <div className="labels" key={index}>
              {chunkedOrders.map((order, index) => {
                return (
                  <div className="labels__label" key={index}>
                    <div className="labels__label__shippingAddress">
                      〒{order.customer.zip}
                      <br />
                      {order.customer.province}
                      {order.customer.city}
                      <br />
                      {order.customer.address1}
                      {order.customer.address2}
                      <br />
                      {order.customer.lastName} {order.customer.firstName} 様
                    </div>
                    <div className="labels__label__returnAddress">
                      【差出人・返送先】〒{template.postal_code ?? ""}
                      <br />
                      {template.address1 ?? ""}
                      {template.address2 ? (
                        <>
                          <br />
                          {template.address2}
                        </>
                      ) : null}
                      {template.address3 ? (
                        <>
                          <br />
                          {template.address3}
                        </>
                      ) : null}
                    </div>
                    <div className="labels__label__qr">
                      <div className="labels__label__qr__desc">
                        {template.link_desc}
                      </div>
                      <QRCode
                        value={template.link}
                        size={46}
                        renderAs="svg"
                        level="L"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
);
