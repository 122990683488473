import LogRocket from "logrocket";
import { useState } from "react";

export const useLogRocket = () => {
  const [logRocketInited, setLogRocketInited] = useState(false);
  const [recordingURL, setRecordingURL] = useState("");

  const initLogRocket = () => {
    if (process.env.NODE_ENV === "test") return;
    if (logRocketInited) return;

    LogRocket.init(process.env.REACT_APP_LOGROCKET ?? "");

    const params = new URLSearchParams(window.location.search);
    const shop = params.get("shop") ?? "";
    LogRocket.identify(shop);

    LogRocket.getSessionURL((sessionURL) => {
      setRecordingURL(sessionURL);
    });

    setLogRocketInited(true);
  };

  return { recordingURL, initLogRocket };
};
