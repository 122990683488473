import { createContext } from "react";

type LogRocket = {
  recordingURL: string;
};

/* eslint-disable */
export const LogRocketContext = createContext<LogRocket>({
  recordingURL: "",
});
/* eslint-enable */
