import styled from "styled-components";
import { ReactNode } from "react";
import { colors } from "../../lib/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { indicationIcon } from "../../styles/icons";

type Props = {
  collapse: boolean;
  setCollapse: () => void;
  children?: ReactNode;
};

export const SideMenu: React.FC<Props> = ({
  collapse,
  setCollapse,
  children,
}) => {
  return (
    <>
      {collapse ? (
        <SideMenuCollapsedComponent>
          <SideMenuOpener onClick={() => setCollapse()}>
            <FontAwesomeIcon icon={faAnglesLeft} style={indicationIcon} />
          </SideMenuOpener>
        </SideMenuCollapsedComponent>
      ) : (
        <SideMenuComponent>
          {children}
          <SideMenuCloser onClick={() => setCollapse()}>
            <FontAwesomeIcon icon={faAnglesRight} style={indicationIcon} />
          </SideMenuCloser>
        </SideMenuComponent>
      )}
    </>
  );
};

const SideMenuComponent = styled.div`
  position: fixed;
  right: 0;
  width: 560px;
  height: calc(100% - 32px);
  background-color: ${colors.gray};
  padding: 16px;
  border-radius: 8px 0 0 8px;
`;

const SideMenuCollapsedComponent = styled(SideMenuComponent)`
  width: 32px;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 0px;
`;

const SideMenuCloser = styled.div`
  width: 100%;
  height: 32px;
  background-color: ${colors.darkGray};
  position: absolute;
  bottom: 0px;
  left: 0;
  border-radius: 0px 0px 0px 8px;
  cursor: pointer;
`;

const SideMenuOpener = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${colors.darkGray};
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
`;
