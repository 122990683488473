import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  blur: boolean;
  setBlur: () => void;
  children: ReactNode;
};

export const MainContent: React.FC<Props> = ({ blur, setBlur, children }) => {
  return blur ? (
    <>
      <MainContentBluredComponent>{children}</MainContentBluredComponent>
      <BlurComponent onClick={() => setBlur()} />
    </>
  ) : (
    <MainContentComponent>{children}</MainContentComponent>
  );
};

const MainContentComponent = styled.div`
  flex: 1;
  padding: 32px 64px 32px 32px;
  height: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MainContentBluredComponent = styled(MainContentComponent)`
  filter: blur(2px);
`;

const BlurComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.4;
`;
