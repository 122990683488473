import styled from "styled-components";

import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const SubStepTitle: React.FC<Props> = (props) => {
  return <SubStepTitleComponent>{props.children}</SubStepTitleComponent>;
};

const SubStepTitleComponent = styled.div`
  display: flex;
  margin-bottom: 8px;
`;
