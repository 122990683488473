import { ITemplatePage, TemplatePage } from "../../pages/template";
import {
  CloudinaryRepository,
  ICloudinaryRepository,
} from "../../repositories/cloudinary";
import { ILayoutRepository, LayoutRepository } from "../../repositories/layout";
import { ISheetRepository, SheetRepository } from "../../repositories/sheet";
import { IStoreRepository, StoreRepository } from "../../repositories/store";
import {
  ITemplateRepository,
  TemplateRepository,
} from "../../repositories/template";
import {
  CloudinaryUsecase,
  ICloudinaryUsecase,
} from "../../usecases/cloudinary";
import { ILayoutUsecase, LayoutUsecase } from "../../usecases/layout";
import { ISheetUsecase, SheetUsecase } from "../../usecases/sheet";
import { IStoreUsecase, StoreUsecase } from "../../usecases/store";
import { ITemplateUsecase, TemplateUsecase } from "../../usecases/template";

const injectStoreRepository = (): IStoreRepository => {
  return new StoreRepository();
};

const injectSheetRepository = (): ISheetRepository => {
  return new SheetRepository();
};

const injectLayoutRepository = (): ILayoutRepository => {
  return new LayoutRepository();
};

const injectTemplateRepository = (): ITemplateRepository => {
  return new TemplateRepository();
};

const injectCloudinaryRepository = (): ICloudinaryRepository => {
  return new CloudinaryRepository();
};

const injectStoreUsecase = (): IStoreUsecase => {
  const storeRepository = injectStoreRepository();
  return new StoreUsecase(storeRepository);
};

const injectSheetUsecase = (): ISheetUsecase => {
  const sheetRepository = injectSheetRepository();
  return new SheetUsecase(sheetRepository);
};

const injectLayoutUsecase = (): ILayoutUsecase => {
  const layoutRepository = injectLayoutRepository();
  return new LayoutUsecase(layoutRepository);
};

const injectTemplateUsecase = (): ITemplateUsecase => {
  const templateRepository = injectTemplateRepository();
  return new TemplateUsecase(templateRepository);
};

const injectCloudinaryUsecase = (): ICloudinaryUsecase => {
  const cloudinaryRepository = injectCloudinaryRepository();
  return new CloudinaryUsecase(cloudinaryRepository);
};

export const injectTemplatePage = (): ITemplatePage => {
  const storeUsecase = injectStoreUsecase();
  const sheetUsecase = injectSheetUsecase();
  const layoutUsecase = injectLayoutUsecase();
  const templateUsecase = injectTemplateUsecase();
  const cloudinaryUsecase = injectCloudinaryUsecase();
  return new TemplatePage(
    storeUsecase,
    sheetUsecase,
    layoutUsecase,
    templateUsecase,
    cloudinaryUsecase
  );
};
