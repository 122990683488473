import { ILayoutRepository } from "../../repositories/layout";
import { Layout } from "../../types/layout";

export interface ILayoutUsecase {
  listLayouts(sheetId: string): Promise<Layout[]>;
}

export class LayoutUsecase implements ILayoutUsecase {
  constructor(private layoutRepository: ILayoutRepository) {}

  async listLayouts(sheetId: string): Promise<Layout[]> {
    return this.layoutRepository.listLayouts(sheetId);
  }
}
