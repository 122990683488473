import { colors } from "../../lib/colors";

export const subTitleIcon: React.CSSProperties = {
  fontSize: 24,
  lineHeight: 32,
  verticalAlign: "middle",
  height: 32,
  marginRight: 4,
  color: colors.text,
};

export const indicationIcon: React.CSSProperties = {
  fontSize: 16,
  lineHeight: 16,
  verticalAlign: "middle",
  height: 16,
  margin: "8px",
  color: colors.text,
};
