import styled from "styled-components";
import { colors } from "../../lib/colors";

import { ReactNode } from "react";

type Props = {
  selected: boolean;
  setSelected: () => void;
  children: ReactNode;
};

export const RadioItemLarge: React.FC<Props> = ({
  selected,
  setSelected,
  children,
}) => {
  return (
    <>
      {selected ? (
        <RadioItemLargeComponentSelected>
          {children}
        </RadioItemLargeComponentSelected>
      ) : (
        <RadioItemLargeComponent onClick={setSelected}>
          {children}
        </RadioItemLargeComponent>
      )}
    </>
  );
};

const RadioItemLargeComponent = styled.div`
  padding: 8px 16px 16px;
  background-color: ${colors.white};
  border-radius: 4px;
  cursor: pointer;
`;

const RadioItemLargeComponentSelected = styled(RadioItemLargeComponent)`
  border: solid 1px ${colors.primary};
`;
