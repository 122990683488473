import { useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { Store } from "../../types/store";
import { Loading } from "../../ui/loading";
import { MainContent } from "../../ui/mainContent";
import { SideMenu } from "../../ui/sideMenu";
import { Step } from "../../ui/step";
import { StepTitle } from "../../ui/stepTitle";
import { Title } from "../../ui/title";
import { IStoreUsecase } from "../../usecases/store";
import { TemplateKcc20zHWrk6eqylThP2t } from "../../templates/Kcc20zHWrk6eqylThP2t";
import { Template } from "../../types/template";
import { subTitleIcon } from "../../styles/icons";
import { ITemplateUsecase } from "../../usecases/template";
import { TextInput } from "../../ui/textInput";
import { SubButton } from "../../ui/subButton";
import { IShopifyUsecase } from "../../usecases/shopify";
import { Button } from "../../ui/button";
import { useRef } from "react";
import { PrintKcc20zHWrk6eqylThP2t } from "../../prints/Kcc20zHWrk6eqylThP2t";
import { Description } from "../../ui/description";
import { Order } from "../../types/order";
import { ILinkUsecase } from "../../usecases/link";
import { TypeKcc20zHWrk6eqylThP2t } from "../../types/template/Kcc20zHWrk6eqylThP2t";
import { ScrollArea } from "../../ui/scrollArea";
import { SettingsArea } from "../../ui/settingsArea";
import { TemplateHYGZJnNu9m6rnHRIBe9b } from "../../templates/hYGZJnNu9m6rnHRIBe9b";
import { TemplateAHhQHBCyHCoNjouPELeu } from "../../templates/AHhQHBCyHCoNjouPELeu";
import { TypeHYGZJnNu9m6rnHRIBe9b } from "../../types/template/hYGZJnNu9m6rnHRIBe9b";
import { TypeAHhQHBCyHCoNjouPELeu } from "../../types/template/AHhQHBCyHCoNjouPELeu";
import { PrintHYGZJnNu9m6rnHRIBe9b } from "../../prints/hYGZJnNu9m6rnHRIBe9b";
import { PrintAHhQHBCyHCoNjouPELeu } from "../../prints/AHhQHBCyHCoNjouPELeu";

export interface IOrdersPage {
  print: React.FC;
}

export class OrdersPage implements IOrdersPage {
  constructor(
    private storeUsecase: IStoreUsecase,
    private templateUsecase: ITemplateUsecase,
    private shopifyUsecase: IShopifyUsecase,
    private linkUsecase: ILinkUsecase
  ) {}

  print: React.FC = () => {
    const [store, setStore] = useState<Store | null>(null);
    const [templates, setTemplates] = useState<
      (Template & (TypeKcc20zHWrk6eqylThP2t | {}))[]
    >([]);
    const [template, setTemplate] = useState<Template & object>();
    const [sideMenuStatus, setSideMenuStatus] = useState<boolean>(false);
    const [tagName, setTagName] = useState<string>("");
    const [orders, setOrders] = useState<(Order & { linkId: string })[]>([]);
    const [verticalPosition, setVerticalPosition] = useState<string>("0");
    const [horizontalPosition, setHorizontalPosition] = useState<string>("0");
    const [horizontalScale, setHorizontalScale] = useState<string>("1");
    const [verticalScale, setVerticalScale] = useState<string>("1");

    const params = new URLSearchParams(window.location.search);
    const shop = params.get("shop") ?? "";
    const orderIds = params.getAll("ids[]");

    const printRef = useRef<HTMLDivElement>(null);

    const init = () => {
      this.storeUsecase.getStore(shop).then((data) => {
        if (!data || !data.store) {
          console.error(`OrdersPage.print: store is not exists`);
          return;
        }
        if (data.store.installed_at) {
          setStore(data.store);
          setHorizontalPosition(
            (data.store.horizontal_position ?? 0).toString()
          );
          setVerticalPosition((data.store.vertical_position ?? 0).toString());
          setHorizontalScale((data.store.horizontal_scale ?? 1.0).toString());
          setVerticalScale((data.store.vertical_scale ?? 1.0).toString());
        } else {
          window.location.href = `https://${shop}/admin/oauth/authorize?client_id=${
            data.store.client_id
          }&scope=read_orders,write_orders,read_customers&redirect_uri=${
            process.env.REACT_APP_HOST as string
          }/oauth&grant_options[]=value`;
        }
      });

      this.templateUsecase.listTemplates(shop).then((data) => {
        if (data.length > 0) setSideMenuStatus(true);
        setTemplates(data);
      });

      this.shopifyUsecase.getOrders(shop, orderIds).then((data) => {
        if (data)
          setOrders(
            data.map((order) => ({
              ...order,
              linkId: window.self.crypto.randomUUID(),
            }))
          );
      });
    };

    const initTemplate = () => {
      if (templates.length > 0 && store?.default_template) {
        setTemplate(templates.find((el) => el.id === store.default_template));
      }
    };

    const updateStore = (store: Store) => {
      this.storeUsecase.updateStore(store).then((data) => {
        if (!data) return;
        setStore(data);
        setHorizontalPosition((data.horizontal_position ?? 0).toString());
        setVerticalPosition((data.vertical_position ?? 0).toString());
        setHorizontalScale((data.horizontal_scale ?? 1.0).toString());
        setVerticalScale((data.vertical_scale ?? 1.0).toString());
        alert("印刷位置が保存されました。");
      });
    };

    const applyTag = () => {
      if (tagName.length === 0) {
        return;
      } else {
        this.shopifyUsecase.applyTag(shop, tagName, orderIds).then(() => {
          alert("タグ付けが完了しました。");
        });
      }
    };

    useEffect(() => {
      init();
    }, []);

    useEffect(() => {
      initTemplate();
    }, [templates, store]);

    return (
      <>
        {store ? (
          <div
            style={{
              display: "flex",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <MainContent
              blur={sideMenuStatus}
              setBlur={() => setSideMenuStatus((prev) => !prev)}
            >
              <SettingsArea>
                <Title title="印刷位置の調整" />
                <div
                  style={{
                    padding: "16px 0",
                  }}
                >
                  <TextInput
                    title="上下拡大・縮小"
                    onChange={(value) => setVerticalScale(value)}
                    defaultValue={verticalScale}
                    description="0.95〜1.05"
                  />
                  <TextInput
                    title="左右拡大・縮小"
                    onChange={(value) => setHorizontalScale(value)}
                    defaultValue={horizontalScale}
                    description="0.95〜1.05"
                  />
                  <TextInput
                    title="上下位置"
                    onChange={(value) => setVerticalPosition(value)}
                    defaultValue={verticalPosition}
                    description="-5〜5"
                  />
                  <TextInput
                    title="左右位置"
                    onChange={(value) => setHorizontalPosition(value)}
                    defaultValue={horizontalPosition}
                    description="-5〜5"
                  />
                </div>
                <SubButton
                  title="保存"
                  onClick={() =>
                    updateStore({
                      ...store,
                      horizontal_position: horizontalPosition,
                      vertical_position: verticalPosition,
                      horizontal_scale: horizontalScale,
                      vertical_scale: verticalScale,
                    })
                  }
                />
              </SettingsArea>
              <Step>
                <Description description="選択されているテンプレートをご確認の上、下記ボタンから印刷して下さい。" />
                <div style={{ display: "none" }}>
                  {template ? (
                    template.layout === "Kcc20zHWrk6eqylThP2t" ? (
                      <PrintKcc20zHWrk6eqylThP2t
                        orders={orders}
                        template={template}
                        verticalPosition={parseFloat(verticalPosition)}
                        verticalScale={parseFloat(verticalScale)}
                        ref={printRef}
                      />
                    ) : template.layout === "hYGZJnNu9m6rnHRIBe9b" ? (
                      <PrintHYGZJnNu9m6rnHRIBe9b
                        orders={orders}
                        template={
                          template as Template & TypeHYGZJnNu9m6rnHRIBe9b
                        }
                        verticalPosition={parseFloat(verticalPosition)}
                        verticalScale={parseFloat(verticalScale)}
                        ref={printRef}
                      />
                    ) : template.layout === "AHhQHBCyHCoNjouPELeu" ? (
                      <PrintAHhQHBCyHCoNjouPELeu
                        orders={orders}
                        template={
                          template as Template & TypeAHhQHBCyHCoNjouPELeu
                        }
                        horizontalPosition={parseFloat(
                          horizontalPosition ?? "0"
                        )}
                        verticalPosition={parseFloat(verticalPosition ?? "0")}
                        horizontalScale={parseFloat(horizontalScale ?? "1")}
                        verticalScale={parseFloat(verticalScale ?? "1")}
                        ref={printRef}
                      />
                    ) : null
                  ) : null}
                </div>
                <ReactToPrint
                  trigger={() => (
                    <Button title="印刷" onClick={() => console.log("印刷")} />
                  )}
                  onBeforePrint={() => {
                    // if (template) {
                    //   this.linkUsecase.createLinks(orders, template);
                    // }
                  }}
                  content={() => printRef.current}
                />
              </Step>
              <Step>
                <div
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    justifyContent: "center",
                  }}
                >
                  <TextInput
                    onChange={(text) => {
                      setTagName(text);
                    }}
                    placeholder="タグ名を入力"
                  />
                  <SubButton
                    title="タグ付け"
                    onClick={() => {
                      applyTag();
                    }}
                  />
                </div>
              </Step>
            </MainContent>
            <SideMenu
              collapse={!sideMenuStatus}
              setCollapse={() => setSideMenuStatus((prev) => !prev)}
            >
              <StepTitle>
                <FontAwesomeIcon icon={faFloppyDisk} style={subTitleIcon} />
                <Title title="テンプレート" />
              </StepTitle>
              <ScrollArea>
                {templates.length > 0 ? (
                  <>
                    {templates.map((template) =>
                      template.layout === "Kcc20zHWrk6eqylThP2t" ? (
                        <TemplateKcc20zHWrk6eqylThP2t
                          template={
                            template as Template & TypeKcc20zHWrk6eqylThP2t
                          }
                          default_template={store.default_template ?? ""}
                          onClick={() => {
                            setTemplate(template);
                            setStore((prev) =>
                              prev
                                ? { ...prev, default_template: template.id }
                                : null
                            );
                            this.storeUsecase.updateDefaultTemplate(
                              store.shop,
                              template.id
                            );
                          }}
                        />
                      ) : template.layout === "hYGZJnNu9m6rnHRIBe9b" ? (
                        <TemplateHYGZJnNu9m6rnHRIBe9b
                          template={
                            template as Template & TypeHYGZJnNu9m6rnHRIBe9b
                          }
                          default_template={store.default_template ?? ""}
                          onClick={() => {
                            setStore((prev) =>
                              prev
                                ? { ...prev, default_template: template.id }
                                : null
                            );
                            this.storeUsecase.updateDefaultTemplate(
                              store.shop,
                              template.id
                            );
                          }}
                        />
                      ) : template.layout === "AHhQHBCyHCoNjouPELeu" ? (
                        <TemplateAHhQHBCyHCoNjouPELeu
                          template={
                            template as Template & TypeAHhQHBCyHCoNjouPELeu
                          }
                          default_template={store.default_template ?? ""}
                          onClick={() => {
                            setStore((prev) =>
                              prev
                                ? { ...prev, default_template: template.id }
                                : null
                            );
                            this.storeUsecase.updateDefaultTemplate(
                              store.shop,
                              template.id
                            );
                          }}
                        />
                      ) : null
                    )}
                  </>
                ) : null}
              </ScrollArea>
            </SideMenu>
          </div>
        ) : (
          <Loading description="認証中です。画面をそのままにしてお待ち下さい。" />
        )}
      </>
    );
  };
}
