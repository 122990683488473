import { css } from "styled-components";
import { colors } from "../../lib/colors";

export const fonts = {
  title: css`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  `,
  subTitle: css`
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
  `,
  itemTitle: css`
    font-size: 14px;
    font-weight: 600;
    color: ${colors.text};
  `,
  description: css`
    font-size: 13.5px;
    font-weight: 400;
    color: ${colors.text};
  `,
};
