import styled from "styled-components";
import { colors } from "../../lib/colors";
import { fonts } from "../../styles/fonts";

type Props = {
  description: string;
};

export const Loading: React.FC<Props> = ({ description }) => {
  return (
    <LoadingComponent>
      <LoadingDescription>{description}</LoadingDescription>
    </LoadingComponent>
  );
};

const LoadingComponent = styled.div`
  width: 100%;
  margin-top: 10vh;
`;

const LoadingDescription = styled.p`
  ${fonts.description};
  text-align: center;
  margin-bottom: 5vh;
`;
