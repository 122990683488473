import { IOrdersPage, OrdersPage } from "../../pages/orders";
import { ILinkRepository, LinkRepository } from "../../repositories/link";
import {
  IShopifyRepository,
  ShopifyRepository,
} from "../../repositories/shopify";
import { IStoreRepository, StoreRepository } from "../../repositories/store";
import {
  ITemplateRepository,
  TemplateRepository,
} from "../../repositories/template";
import { ILinkUsecase, LinkUsecase } from "../../usecases/link";
import { IShopifyUsecase, ShopifyUsecase } from "../../usecases/shopify";
import { IStoreUsecase, StoreUsecase } from "../../usecases/store";
import { ITemplateUsecase, TemplateUsecase } from "../../usecases/template";

const injectStoreRepository = (): IStoreRepository => {
  return new StoreRepository();
};

const injectTemplateRepository = (): ITemplateRepository => {
  return new TemplateRepository();
};

const injectShopifyRepository = (): IShopifyRepository => {
  return new ShopifyRepository();
};

const injectLinkRepository = (): ILinkRepository => {
  return new LinkRepository();
};

const injectStoreUsecase = (): IStoreUsecase => {
  const storeRepository = injectStoreRepository();
  return new StoreUsecase(storeRepository);
};

const injectTemplateUsecase = (): ITemplateUsecase => {
  const templateRepository = injectTemplateRepository();
  return new TemplateUsecase(templateRepository);
};

const injectShopifyUsecase = (): IShopifyUsecase => {
  const shopifyRepository = injectShopifyRepository();
  return new ShopifyUsecase(shopifyRepository);
};

const injectLinkUsecase = (): ILinkUsecase => {
  const linkRepository = injectLinkRepository();
  return new LinkUsecase(linkRepository);
};

export const injectOrdersPage = (): IOrdersPage => {
  const storeUsecase = injectStoreUsecase();
  const templateUsecase = injectTemplateUsecase();
  const shopifyUsecase = injectShopifyUsecase();
  const linkUsecase = injectLinkUsecase();
  return new OrdersPage(
    storeUsecase,
    templateUsecase,
    shopifyUsecase,
    linkUsecase
  );
};
