import styled from "styled-components";
import { colors } from "../../lib/colors";
import { fonts } from "../../styles/fonts";

type Props = {
  title: string;
  onClick: () => void;
};

export const SubButton: React.FC<Props> = ({ title, onClick }) => {
  return <SubButtonComponent onClick={onClick}>{title}</SubButtonComponent>;
};

const SubButtonComponent = styled.div`
  ${fonts.subTitle};
  color: ${colors.text};
  cursor: pointer;
  width: 120px;
  height: 38px;
  border-radius: 2px;
  line-height: 38px;
  text-align: center;
  background-color: ${colors.darkGray};
`;
