import styled from "styled-components";
import { colors } from "../../lib/colors";

type Props = {
  src: string;
};

export const LabelItemImage: React.FC<Props> = ({ src }) => {
  return <LabelItemImageComponent src={src} />;
};

const LabelItemImageComponent = styled.img`
  height: 160px;
  margin: 0 auto;
  display: block;
`;
