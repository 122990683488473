import { BrowserRouter, Route, Routes } from "react-router-dom";
import { injectTemplatePage } from "./injectors/template";
import { injectStorePage } from "./injectors/store";
import { injectOrdersPage } from "./injectors/orders";
import { LogRocketContext } from "./contexts/logRocketContext";
import { useLogRocket } from "./hooks/useLogRocket";

const App = () => {
  const { recordingURL, initLogRocket } = useLogRocket();
  initLogRocket();

  const templatePage = injectTemplatePage();
  const storePage = injectStorePage();
  const ordersPage = injectOrdersPage();

  return (
    <LogRocketContext.Provider value={{ recordingURL }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<templatePage.setting />} />
          <Route path="/oauth" element={<storePage.oauth />} />
          <Route path="/print" element={<ordersPage.print />} />
        </Routes>
      </BrowserRouter>
    </LogRocketContext.Provider>
  );
};

export default App;
