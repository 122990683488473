import styled from "styled-components";
import { colors } from "../lib/colors";
import { fonts } from "../styles/fonts";
import { Template } from "../types/template";
import { TypeAHhQHBCyHCoNjouPELeu } from "../types/template/AHhQHBCyHCoNjouPELeu";
import { ItemImage } from "../ui/itemImage";
import { RadioItemIndicator } from "../ui/radioItemIndicator";
import { RadioItemLarge } from "../ui/radioItemLarge";
import { SubTitle } from "../ui/subTitle";

type Props = {
  template: Template & TypeAHhQHBCyHCoNjouPELeu;
  default_template: string;
  onClick: () => void;
};

export const TemplateAHhQHBCyHCoNjouPELeu: React.FC<Props> = ({
  template,
  default_template,
  onClick,
}) => {
  return (
    <TemplateAHhQHBCyHCoNjouPELeuComponent
      selected={default_template === template.id}
      onClick={onClick}
    >
      <RadioItemLarge
        selected={false}
        setSelected={() => console.log("selected")}
      >
        <TitleRow>
          <RadioItemIndicator selected={default_template === template.id} />
          <SubTitle title={template.title} />
        </TitleRow>
        <Content>
          <ContentLeft>
            <ItemImage src="https://res.cloudinary.com/ds0kl3kxb/image/upload/v1696152513/Atmify/layouts/AHhQHBCyHCoNjouPELeu_ebwlgm.jpg" />
            <SheetName>A4 27面 四辺余白付 角丸</SheetName>
          </ContentLeft>
          <ContentRight>
            <ItemTitle>依頼主</ItemTitle>
            <Item>{template.shipping_from ?? ""}</Item>
            <Item>{template.postal_code ?? ""}</Item>
            <Item>{template.address ?? ""}</Item>
            <ItemsBlockRow>
              <ItemTitle>品名</ItemTitle>
              <ItemRow>
                <Item>{template.item ?? ""}</Item>
              </ItemRow>
            </ItemsBlockRow>
            <ItemsBlockRow>
              <ItemTitle>配達員様へ</ItemTitle>
              <Item>{template.message ?? ""}</Item>
            </ItemsBlockRow>
          </ContentRight>
        </Content>
      </RadioItemLarge>
    </TemplateAHhQHBCyHCoNjouPELeuComponent>
  );
};

type StyledProps = {
  selected?: boolean;
};

const TemplateAHhQHBCyHCoNjouPELeuComponent = styled.div<StyledProps>`
  width: 100%;
  margin: 16px auto 0;
  border: solid 1px ${({ selected }) => (selected ? colors.primary : "none")};
  border-radius: 4px;
  box-sizing: border-box;
`;

const TitleRow = styled.div`
  display: flex;
  margin: 0 0 8px;
`;

const Content = styled.div`
  display: flex;
`;

const ContentLeft = styled.div`
  width: 40%;
`;

const SheetName = styled.p`
  ${fonts.itemTitle}
  margin: 4px 0 0 8px;
`;

const ContentRight = styled.div`
  width: 58%;
  margin-left: 2%;
`;

const ItemRow = styled.div`
  display: flex;
`;

const Item = styled.p`
  ${fonts.description}
  margin: 0;
  line-height: 21px;
  display: block;
  white-space: pre-wrap;
`;

const ItemsBlockRow = styled.div`
  margin: 8px 0 0;
`;

const ItemTitle = styled.p`
  ${fonts.itemTitle}
  margin: 0;
  line-height: 21px;
`;

const Logo = styled.img`
  height: 72px;
  width: 80%;
  object-fit: contain;
  object-position: left 50%;
  margin: 0;
`;
