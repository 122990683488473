import createApp from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { Layout } from "../../types/layout";
import { Sheet } from "../../types/sheet";
import { Store } from "../../types/store";
import { Template } from "../../types/template";
import { Button } from "../../ui/button";
import { Collection } from "../../ui/collection";
import { Description } from "../../ui/description";
import { ItemImage } from "../../ui/itemImage";
import { ItemTitle } from "../../ui/itemTitle";
import { LabelItemImage } from "../../ui/labelItemImage";
import { Loading } from "../../ui/loading";
import { NumberLabel } from "../../ui/numberLabel";
import { RadioItem } from "../../ui/radioItem";
import { RadioItemHorizontal } from "../../ui/radioItemHorizontal";
import { SettingsArea } from "../../ui/settingsArea";
import { SideMenu } from "../../ui/sideMenu";
import { Step } from "../../ui/step";
import { StepTitle } from "../../ui/stepTitle";
import { Title } from "../../ui/title";
import { ILayoutUsecase } from "../../usecases/layout";
import { ISheetUsecase } from "../../usecases/sheet";
import { IStoreUsecase } from "../../usecases/store";
import { ITemplateUsecase } from "../../usecases/template";
import { ICloudinaryUsecase } from "../../usecases/cloudinary";
import { MainContent } from "../../ui/mainContent";
import { TemplateKcc20zHWrk6eqylThP2t } from "../../templates/Kcc20zHWrk6eqylThP2t";
import { subTitleIcon } from "../../styles/icons";
import { TypeKcc20zHWrk6eqylThP2t } from "../../types/template/Kcc20zHWrk6eqylThP2t";
import { TypeHYGZJnNu9m6rnHRIBe9b } from "../../types/template/hYGZJnNu9m6rnHRIBe9b";
import { TypeAHhQHBCyHCoNjouPELeu } from "../../types/template/AHhQHBCyHCoNjouPELeu";
import { Kcc20zHWrk6eqylThP2t } from "../../layoutSettings/Kcc20zHWrk6eqylThP2t";
import { ScrollArea } from "../../ui/scrollArea";
import { HYGZJnNu9m6rnHRIBe9b } from "../../layoutSettings/hYGZJnNu9m6rnHRIBe9b";
import { AHhQHBCyHCoNjouPELeu } from "../../layoutSettings/AHhQHBCyHCoNjouPELeu";
import { TemplateHYGZJnNu9m6rnHRIBe9b } from "../../templates/hYGZJnNu9m6rnHRIBe9b";
import { TemplateAHhQHBCyHCoNjouPELeu } from "../../templates/AHhQHBCyHCoNjouPELeu";

export const expectedAccessScopes = [
  "read_orders",
  "write_orders",
  "read_customers",
];

export interface ITemplatePage {
  setting: React.FC;
}

export class TemplatePage implements ITemplatePage {
  constructor(
    private storeUsecase: IStoreUsecase,
    private sheetUsecase: ISheetUsecase,
    private layoutUsecase: ILayoutUsecase,
    private templateUsecase: ITemplateUsecase,
    private cloudinaryUsecase: ICloudinaryUsecase
  ) {}

  setting: React.FC = () => {
    const [template, setTemplate] = useState<
      Template & (TypeKcc20zHWrk6eqylThP2t | TypeHYGZJnNu9m6rnHRIBe9b | {})
    >({
      id: "",
      title: "",
      store: "",
      layout: "",
      link: "",
    });

    const [store, setStore] = useState<Store | null>(null);
    const [sheets, setSheets] = useState<Sheet[]>([]);
    const [sheet, setSheet] = useState<Sheet | null>(null);
    const [layouts, setLayouts] = useState<Layout[]>([]);
    const [templates, setTemplates] = useState<
      (Template & (TypeKcc20zHWrk6eqylThP2t | TypeHYGZJnNu9m6rnHRIBe9b | {}))[]
    >([]);
    const [sideMenuStatus, setSideMenuStatus] = useState<boolean>(false);

    const params = new URLSearchParams(window.location.search);
    const shop = params.get("shop") ?? "";
    const appHost = params.get("host") ?? "";

    const init = () => {
      this.storeUsecase.getStore(shop).then((data) => {
        if (!data || !data.store) {
          console.error(`TemplatePage.setting: store is not exists`);
          return;
        }
        if (data.store.installed_at) {
          setStore(data.store);
          if (
            expectedAccessScopes.find(
              (eas) => data.access_scopes.indexOf(eas) === -1
            )
          ) {
            const scopes = expectedAccessScopes.join();
            const linkTo = `https://${shop}/admin/oauth/authorize?client_id=${
              data.store.client_id
            }&scope=${scopes}&redirect_uri=${
              process.env.REACT_APP_HOST as string
            }/oauth&grant_options[]=value`;
            if (window.top === window.self) {
              window.location.assign(linkTo);
            } else {
              Redirect.create(
                createApp({ apiKey: data.store.client_id, host: appHost })
              ).dispatch(Redirect.Action.REMOTE, linkTo);
            }
            return;
          }
        } else {
          window.location.href = `https://${shop}/admin/oauth/authorize?client_id=${
            data.store.client_id
          }&scope=read_orders,write_orders,read_customers&redirect_uri=${
            process.env.REACT_APP_HOST as string
          }/oauth&grant_options[]=value`;
        }
      });

      this.sheetUsecase.listAllSheets().then((data) => {
        setSheets(data);
      });

      this.templateUsecase.listTemplates(shop).then((data) => {
        if (data.length > 0) setSideMenuStatus(true);
        setTemplates(data);
      });
    };

    const initTemplate = (data: {
      id?: string;
      title?: string;
      layout?: string;
    }) => {
      setTemplate((prev) => ({
        ...prev,
        id: data.id ?? prev.id,
        title: data.title ?? prev.title,
        layout: data.layout ?? prev.layout,
      }));
      setLayouts((prev) => {
        return [...prev];
      });
    };

    const updateLayouts = () => {
      this.layoutUsecase.listLayouts(sheet?.id ?? "").then((data) => {
        setLayouts(data);
      });
    };

    const save = () => {
      this.templateUsecase
        .createTemplate({ ...template, store: store?.shop ?? "" })
        .then((_id) => {
          init();
        });
    };

    useEffect(() => {
      init();
    }, []);

    useEffect(() => {
      updateLayouts();
    }, [sheet]);

    return (
      <>
        {store ? (
          <div
            style={{
              display: "flex",
              height: "100vh",
              overflow: "hidden",
              paddingBottom: "32px",
            }}
          >
            <MainContent
              blur={sideMenuStatus}
              setBlur={() => setSideMenuStatus((prev) => !prev)}
            >
              <Step>
                <StepTitle>
                  <NumberLabel number="1" />
                  <Title title="用紙を選択" />
                </StepTitle>
                <Collection>
                  {sheets.map((el) => {
                    return (
                      <RadioItem
                        selected={el.id === sheet?.id}
                        setSelected={() => {
                          setSheet(el);
                        }}
                      >
                        <ItemImage src={el.img} />
                        <ItemTitle title={el.title} />
                      </RadioItem>
                    );
                  })}
                </Collection>
              </Step>

              <Step>
                <StepTitle>
                  <NumberLabel number="2" />
                  <Title title="レイアウトを選択" />
                </StepTitle>
                <Collection>
                  {layouts.length === 0 ? (
                    <Description description="先に用紙を選択して下さい。" />
                  ) : (
                    layouts.map((layout) => {
                      return (
                        <RadioItemHorizontal
                          selected={template.layout === layout.id}
                          setSelected={() =>
                            initTemplate({ layout: layout.id })
                          }
                        >
                          <LabelItemImage src={layout.img} />
                        </RadioItemHorizontal>
                      );
                    })
                  )}
                </Collection>
              </Step>

              <Step>
                <StepTitle>
                  <NumberLabel number="3" />
                  <Title title="テンプレートの設定" />
                </StepTitle>
                <SettingsArea>
                  {template.layout === "" ? (
                    <Description description="先にレイアウトを選択して下さい。" />
                  ) : template.layout === "Kcc20zHWrk6eqylThP2t" ? (
                    <Kcc20zHWrk6eqylThP2t
                      setTemplate={
                        setTemplate as React.Dispatch<
                          React.SetStateAction<
                            Template & TypeKcc20zHWrk6eqylThP2t
                          >
                        >
                      }
                      convertImage={(image: ArrayBuffer) =>
                        this.cloudinaryUsecase.createAsset(image, store.shop)
                      }
                    />
                  ) : template.layout === "hYGZJnNu9m6rnHRIBe9b" ? (
                    <HYGZJnNu9m6rnHRIBe9b
                      setTemplate={
                        setTemplate as React.Dispatch<
                          React.SetStateAction<
                            Template & TypeHYGZJnNu9m6rnHRIBe9b
                          >
                        >
                      }
                    />
                  ) : template.layout === "AHhQHBCyHCoNjouPELeu" ? (
                    <AHhQHBCyHCoNjouPELeu
                      setTemplate={
                        setTemplate as React.Dispatch<
                          React.SetStateAction<
                            Template & TypeAHhQHBCyHCoNjouPELeu
                          >
                        >
                      }
                    />
                  ) : (
                    <Description description="先にレイアウトを選択して下さい。" />
                  )}
                </SettingsArea>
              </Step>

              <Button title="テンプレートを保存" onClick={save} />
            </MainContent>
            <SideMenu
              collapse={!sideMenuStatus}
              setCollapse={() => setSideMenuStatus((prev) => !prev)}
            >
              <StepTitle>
                <FontAwesomeIcon icon={faFloppyDisk} style={subTitleIcon} />
                <Title title="テンプレート" />
              </StepTitle>
              <ScrollArea>
                {templates.length > 0 ? (
                  <>
                    {templates.map((template) =>
                      template.layout === "Kcc20zHWrk6eqylThP2t" ? (
                        <TemplateKcc20zHWrk6eqylThP2t
                          template={
                            template as Template & TypeKcc20zHWrk6eqylThP2t
                          }
                          default_template={store.default_template ?? ""}
                          onClick={() => {
                            setStore((prev) =>
                              prev
                                ? { ...prev, default_template: template.id }
                                : null
                            );
                            this.storeUsecase.updateDefaultTemplate(
                              store.shop,
                              template.id
                            );
                          }}
                        />
                      ) : template.layout === "hYGZJnNu9m6rnHRIBe9b" ? (
                        <TemplateHYGZJnNu9m6rnHRIBe9b
                          template={
                            template as Template & TypeHYGZJnNu9m6rnHRIBe9b
                          }
                          default_template={store.default_template ?? ""}
                          onClick={() => {
                            setStore((prev) =>
                              prev
                                ? { ...prev, default_template: template.id }
                                : null
                            );
                            this.storeUsecase.updateDefaultTemplate(
                              store.shop,
                              template.id
                            );
                          }}
                        />
                      ) : template.layout === "AHhQHBCyHCoNjouPELeu" ? (
                        <TemplateAHhQHBCyHCoNjouPELeu
                          template={
                            template as Template & TypeAHhQHBCyHCoNjouPELeu
                          }
                          default_template={store.default_template ?? ""}
                          onClick={() => {
                            setStore((prev) =>
                              prev
                                ? { ...prev, default_template: template.id }
                                : null
                            );
                            this.storeUsecase.updateDefaultTemplate(
                              store.shop,
                              template.id
                            );
                          }}
                        />
                      ) : null
                    )}
                  </>
                ) : null}
              </ScrollArea>
            </SideMenu>
          </div>
        ) : (
          <Loading description="認証中です。画面をそのままにしてお待ち下さい。" />
        )}
      </>
    );
  };
}
