import axios from "axios";
import { Link } from "../../types/link";

export interface ILinkRepository {
  createLinks(links: Link[]): Promise<void>;
}

export class LinkRepository implements ILinkRepository {
  async createLinks(links: Link[]): Promise<void> {
    return axios
      .post(`${process.env.REACT_APP_API as string}/links`, {
        links: links,
      })
      .then((_res) => {
        return;
      })
      .catch((err) => {
        console.error(`LinkRepository.createLink: ${err}`);
        return;
      });
  }
}
