import { IStorePage, StorePage } from "../../pages/store";
import { IStoreRepository, StoreRepository } from "../../repositories/store";
import { IStoreUsecase, StoreUsecase } from "../../usecases/store";

const injectStoreRepository = (): IStoreRepository => {
  return new StoreRepository();
};

const injectStoreUsecase = (): IStoreUsecase => {
  const storeRepository = injectStoreRepository();
  return new StoreUsecase(storeRepository);
};

export const injectStorePage = (): IStorePage => {
  const storeUsecase = injectStoreUsecase();
  return new StorePage(storeUsecase);
};
