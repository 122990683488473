import styled from "styled-components";

import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const ScrollArea: React.FC<Props> = (props) => {
  return <ScrollAreaComponent>{props.children}</ScrollAreaComponent>;
};

const ScrollAreaComponent = styled.div`
  overflow-y: scroll;
  margin-bottom: 58px;
  height: -webkit-fill-available;
`;
