import styled from "styled-components";

import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const StepTitle: React.FC<Props> = (props) => {
  return <StepTitleComponent>{props.children}</StepTitleComponent>;
};

const StepTitleComponent = styled.div`
  display: flex;
  margin-bottom: 8px;
`;
