import { ILinkRepository } from "../../repositories/link";
import { Order } from "../../types/order";
import { Template } from "../../types/template";

export interface ILinkUsecase {
  createLinks(
    orders: (Order & { linkId: string })[],
    template: Template
  ): Promise<void>;
}

export class LinkUsecase implements ILinkUsecase {
  constructor(private linkRepository: ILinkRepository) {}

  async createLinks(
    orders: (Order & { linkId: string })[],
    template: Template
  ): Promise<void> {
    return this.linkRepository.createLinks(
      orders.map((order) => ({
        id: order.linkId,
        store: template.store,
        template: template.id,
        order: order.id,
        url: template.link,
      }))
    );
  }
}
