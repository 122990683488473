import { IStoreRepository } from "../../repositories/store";

import { Store } from "../../types/store";

export interface IStoreUsecase {
  getStore(
    shop: string
  ): Promise<{ store: Store; access_scopes: string[] } | null>;
  setAccessToken(shop: string, code: string): Promise<Store | null>;
  updateStore(store: Store): Promise<Store | null>;
  updateDefaultTemplate(shop: string, template: string): Promise<Store | null>;
}

export class StoreUsecase implements IStoreUsecase {
  constructor(private storeRepository: IStoreRepository) {}

  async getStore(
    shop: string
  ): Promise<{ store: Store; access_scopes: string[] } | null> {
    return this.storeRepository.getStore(shop);
  }

  async setAccessToken(shop: string, code: string): Promise<Store | null> {
    return this.storeRepository.setAccessToken(shop, code);
  }

  async updateStore(store: Store): Promise<Store | null> {
    return this.storeRepository.updateStore(store);
  }

  async updateDefaultTemplate(
    shop: string,
    template: string
  ): Promise<Store | null> {
    return this.storeRepository.updateDefaultTemplate(shop, template);
  }
}
