import { forwardRef } from "react";
import { Order } from "../../types/order";
import "./style.scss";
import { chunkArr } from "../../lib/chunkArr";
import { Template } from "../../types/template";
import { TypeAHhQHBCyHCoNjouPELeu } from "../../types/template/AHhQHBCyHCoNjouPELeu";

type Props = {
  orders: (Order & { linkId: string })[];
  template: Template & TypeAHhQHBCyHCoNjouPELeu;
  horizontalPosition: number;
  verticalPosition: number;
  horizontalScale: number;
  verticalScale: number;
};

export const PrintAHhQHBCyHCoNjouPELeu = forwardRef<HTMLDivElement, Props>(
  (
    {
      orders,
      template,
      horizontalPosition,
      verticalPosition,
      horizontalScale,
      verticalScale,
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className="pages"
        style={{
          transform: `scale(${horizontalScale}, ${verticalScale})`,
          transformOrigin: `${horizontalPosition}px ${verticalPosition}px`,
        }}
      >
        {chunkArr(orders, 27).map((chunkedOrders, index) => {
          return (
            <div className="labels" key={index}>
              {chunkedOrders.map((order, index) => {
                return (
                  <div className="labels__label" key={index}>
                    <div className="labels__label__shippingAddress">
                      <p className="labels__label__shippingAddress__title">
                        お届け先
                      </p>
                      <p className="labels__label__shippingAddress__postalcode">
                        〒{order.customer.zip}
                      </p>
                      <p className="labels__label__shippingAddress__address">
                        {order.customer.province}
                        {order.customer.city}
                        {order.customer.address1}
                        {order.customer.address2}
                      </p>
                      <p className="labels__label__shippingAddress__name">
                        {order.customer.lastName} {order.customer.firstName} 様
                      </p>
                    </div>
                    <div className="labels__label__returnAddress">
                      <p className="labels__label__returnAddress__title">
                        依頼主
                      </p>
                      {template.shipping_from}
                      <br />〒{template.postal_code ?? ""}
                      <br />
                      {template.address ?? ""}
                    </div>
                    <div className="labels__label__desc">
                      <div className="labels__label__desc__item">
                        <p className="labels__label__desc__item__title">品名</p>
                        <p className="labels__label__desc__item__content">
                          {template.item}
                        </p>
                      </div>
                      <div className="labels__label__desc__message">
                        <p className="labels__label__desc__message__title">
                          配達員様へ
                        </p>
                        {template.message}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
);
