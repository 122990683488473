import styled from "styled-components";
import { colors } from "../lib/colors";
import { fonts } from "../styles/fonts";
import { Template } from "../types/template";
import { TypeHYGZJnNu9m6rnHRIBe9b } from "../types/template/hYGZJnNu9m6rnHRIBe9b";
import { ItemImage } from "../ui/itemImage";
import { RadioItemHorizontal } from "../ui/radioItemHorizontal";
import { RadioItemIndicator } from "../ui/radioItemIndicator";
import { RadioItemLarge } from "../ui/radioItemLarge";
import { SubTitle } from "../ui/subTitle";

type Props = {
  template: Template & TypeHYGZJnNu9m6rnHRIBe9b;
  default_template: string;
  onClick: () => void;
};

export const TemplateHYGZJnNu9m6rnHRIBe9b: React.FC<Props> = ({
  template,
  default_template,
  onClick,
}) => {
  return (
    <TemplateHYGZJnNu9m6rnHRIBe9bComponent
      selected={default_template === template.id}
      onClick={onClick}
    >
      <RadioItemLarge
        selected={false}
        setSelected={() => console.log("selected")}
      >
        <TitleRow>
          <RadioItemIndicator selected={default_template === template.id} />
          <SubTitle title={template.title} />
        </TitleRow>
        <Content>
          <ContentLeft>
            <ItemImage src="https://res.cloudinary.com/ds0kl3kxb/image/upload/v1685948890/Atmify/layouts/hYGZJnNu9m6rnHRIBe9b_g2ebjv.jpg" />
            <SheetName>A4 21面 四辺余白付 角丸</SheetName>
          </ContentLeft>
          <ContentRight>
            <ItemRow>
              <Item>【差出人・返送先】</Item>
              <Item>{template.postal_code ?? ""}</Item>
            </ItemRow>
            <ItemRow>
              <Item>
                {template.address1 ?? ""}
                {template.address2 ?? ""}
              </Item>
            </ItemRow>
            <ItemRow>
              <Item>{template.address3 ?? ""}</Item>
            </ItemRow>
            <ItemsBlockRow>
              <ItemTitle>リンク：</ItemTitle>
              <Item>{template.link}</Item>
            </ItemsBlockRow>
            <ItemsBlockRow>
              <ItemTitle>リンクの説明：</ItemTitle>
              <Item>{template.link_desc}</Item>
            </ItemsBlockRow>
          </ContentRight>
        </Content>
      </RadioItemLarge>
    </TemplateHYGZJnNu9m6rnHRIBe9bComponent>
  );
};

type StyledProps = {
  selected?: boolean;
};

const TemplateHYGZJnNu9m6rnHRIBe9bComponent = styled.div<StyledProps>`
  width: 100%;
  margin: 16px auto 0;
  border: solid 1px ${({ selected }) => (selected ? colors.primary : "none")};
  border-radius: 4px;
  box-sizing: border-box;
`;

const TitleRow = styled.div`
  display: flex;
  margin: 0 0 8px;
`;

const Content = styled.div`
  display: flex;
`;

const ContentLeft = styled.div`
  width: 40%;
`;

const SheetName = styled.p`
  ${fonts.itemTitle}
  margin: 4px 0 0 8px;
`;

const ContentRight = styled.div`
  width: 58%;
  margin-left: 2%;
`;

const ItemRow = styled.div`
  display: flex;
`;

const Item = styled.p`
  ${fonts.description}
  margin: 0;
  line-height: 21px;
`;

const ItemsBlockRow = styled.div`
  display: flex;
  margin: 8px 0 0;
`;

const ItemTitle = styled.p`
  ${fonts.itemTitle}
  margin: 0;
  line-height: 21px;
`;

const Logo = styled.img`
  height: 72px;
  width: 80%;
  object-fit: contain;
  object-position: left 50%;
  margin: 0;
`;
