import { updateObjectWithDelete } from "../lib/updateObjectWithDelete";
import { Template } from "../types/template";
import { TypeHYGZJnNu9m6rnHRIBe9b } from "../types/template/hYGZJnNu9m6rnHRIBe9b";
import { SubStep } from "../ui/subStep";
import { SubStepTitle } from "../ui/subStepTitle";
import { SubTitle } from "../ui/subTitle";
import { TextInput } from "../ui/textInput";

type Props = {
  setTemplate: React.Dispatch<
    React.SetStateAction<Template & TypeHYGZJnNu9m6rnHRIBe9b>
  >;
};

export const HYGZJnNu9m6rnHRIBe9b: React.FC<Props> = ({ setTemplate }) => {
  return (
    <>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="1. 差出人・返還先" />
        </SubStepTitle>
        <TextInput
          title="郵便番号"
          onChange={(value) => {
            setTemplate((prev: Template & TypeHYGZJnNu9m6rnHRIBe9b) => ({
              ...prev,
              postal_code: value,
            }));
          }}
        />
        <TextInput
          title="住所 (1行目)"
          onChange={(value) => {
            setTemplate((prev: Template & TypeHYGZJnNu9m6rnHRIBe9b) => ({
              ...prev,
              address1: value,
            }));
          }}
        />
        <TextInput
          title="住所 (2行目)"
          onChange={(value) => {
            setTemplate((prev: any) => {
              return updateObjectWithDelete(prev, "address2", value);
            });
          }}
        />
        <TextInput
          title="住所 (3行目)"
          onChange={(value) => {
            setTemplate((prev: any) => {
              return updateObjectWithDelete(prev, "address3", value);
            });
          }}
        />
      </SubStep>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="2. QRコード" />
        </SubStepTitle>
        <TextInput
          title="リンク先"
          onChange={(value) => {
            setTemplate((prev: Template & TypeHYGZJnNu9m6rnHRIBe9b) => ({
              ...prev,
              link: value,
            }));
          }}
        />
        <TextInput
          title="説明"
          onChange={(value) => {
            setTemplate((prev: Template & TypeHYGZJnNu9m6rnHRIBe9b) => ({
              ...prev,
              link_desc: value,
            }));
          }}
        />
      </SubStep>
      <SubStep>
        <SubStepTitle>
          <SubTitle title="3. テンプレート名" />
        </SubStepTitle>
        <TextInput
          onChange={(value) => {
            setTemplate((prev: Template & TypeHYGZJnNu9m6rnHRIBe9b) => ({
              ...prev,
              title: value,
            }));
          }}
        />
      </SubStep>
    </>
  );
};
