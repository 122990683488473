import styled from "styled-components";
import { fonts } from "../../styles/fonts";

type Props = {
  title?: string;
  onChange: (text: string) => void;
  placeholder?: string;
  defaultValue?: string;
};

export const TextArea: React.FC<Props> = ({
  title,
  onChange,
  placeholder,
  defaultValue,
}) => {
  return (
    <TextInputComponent>
      {title ? <TextInputLabel>{title}</TextInputLabel> : null}
      <TextInputArea
        placeholder={placeholder ?? ""}
        onChange={(e) => onChange(e.target.value)}
        defaultValue={defaultValue}
      />
    </TextInputComponent>
  );
};

const TextInputComponent = styled.div`
  display: flex;
  margin: 0 16px 8px 0;
`;
const TextInputLabel = styled.label`
  ${fonts.itemTitle};
  margin-right: 16px;
  text-align: right;
  line-height: 32px;
  width: 104px;
`;
const TextInputArea = styled.textarea`
  ${fonts.itemTitle};
  height: 32px;
  width: 280px;
`;
