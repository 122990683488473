import styled from "styled-components";
import { colors } from "../../lib/colors";

type Props = {
  selected: boolean;
};

export const RadioItemIndicator: React.FC<Props> = ({ selected }) => {
  return (
    <>
      {selected ? (
        <SelectedRadioItemIndicatorComponent />
      ) : (
        <RadioItemIndicatorComponent />
      )}
    </>
  );
};

const RadioItemIndicatorComponent = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  box-sizing: border-box;
  outline: solid 2px ${colors.gray};
  border: solid 2px white;
  margin: 9px 8px 9px 4px;
`;

const SelectedRadioItemIndicatorComponent = styled(RadioItemIndicatorComponent)`
  outline: solid 2px ${colors.primary};
  background-color: ${colors.primary};
`;
