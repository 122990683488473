import { ISheetRepository } from "../../repositories/sheet";
import { IShopifyRepository } from "../../repositories/shopify";
import { Order } from "../../types/order";

import { Sheet } from "../../types/sheet";

export interface IShopifyUsecase {
  applyTag(
    shop: string,
    tagName: string,
    orderIds: string[]
  ): Promise<"success" | "error">;
  getOrders(shop: string, orderIds: string[]): Promise<Order[] | null>;
}

export class ShopifyUsecase implements IShopifyUsecase {
  constructor(private shopifyRepository: IShopifyRepository) {}

  async applyTag(
    shop: string,
    tagName: string,
    orderIds: string[]
  ): Promise<"success" | "error"> {
    return this.shopifyRepository.applyTag(shop, tagName, orderIds);
  }

  async getOrders(shop: string, orderIds: string[]): Promise<Order[] | null> {
    return this.shopifyRepository.getOrders(shop, orderIds);
  }
}
