export const updateObjectWithDelete = (prev: any, prop: string, value: any) => {
  let newObject = { ...prev };
  if (value) {
    newObject[prop] = value;
  } else {
    if (newObject.hasOwnProperty(prop)) {
      delete newObject[prop];
    }
  }
  return newObject;
};
