import { ICloudinaryRepository } from "../../repositories/cloudinary";

export interface ICloudinaryUsecase {
  createAsset(image: ArrayBuffer, shop: string): Promise<string>;
}

export class CloudinaryUsecase implements ICloudinaryUsecase {
  constructor(private cloudinaryRepository: ICloudinaryRepository) {}

  async createAsset(image: ArrayBuffer, shop: string): Promise<string> {
    return (await this.cloudinaryRepository.createAsset(image, shop)) ?? "";
  }
}
