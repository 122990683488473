import styled from "styled-components";
import { colors } from "../../lib/colors";

import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const SettingsArea: React.FC<Props> = (props) => {
  return <SettingsAreaComponent>{props.children}</SettingsAreaComponent>;
};

const SettingsAreaComponent = styled.div`
  padding: 16px 24px;
  background-color: ${colors.gray};
  border-radius: 2px;
`;
