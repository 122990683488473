import { ISheetRepository } from "../../repositories/sheet";

import { Sheet } from "../../types/sheet";

export interface ISheetUsecase {
  listAllSheets(): Promise<Sheet[]>;
}

export class SheetUsecase implements ISheetUsecase {
  constructor(private sheetRepository: ISheetRepository) {}

  async listAllSheets(): Promise<Sheet[]> {
    return this.sheetRepository.listAllSheets();
  }
}
