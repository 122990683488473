import styled from "styled-components";

import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const Step: React.FC<Props> = (props) => {
  return <StepComponent>{props.children}</StepComponent>;
};

const StepComponent = styled.div`
  width: 100%;
  margin: 32px auto 0;
`;
