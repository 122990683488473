import styled from "styled-components";

import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const SubStep: React.FC<Props> = (props) => {
  return <SubStepComponent>{props.children}</SubStepComponent>;
};

const SubStepComponent = styled.div`
  width: 100%;
  margin: 0 auto 32px;
`;
