import styled from "styled-components";
import { colors } from "../../lib/colors";

import { ReactNode } from "react";

type Props = {
  selected: boolean;
  setSelected: () => void;
  children: ReactNode;
};

export const RadioItem: React.FC<Props> = ({
  selected,
  setSelected,
  children,
}) => {
  return (
    <>
      {selected ? (
        <RadioItemComponentSelected>{children}</RadioItemComponentSelected>
      ) : (
        <RadioItemComponent onClick={setSelected}>
          {children}
        </RadioItemComponent>
      )}
    </>
  );
};

const RadioItemComponent = styled.div`
  width: 160px;
  padding: 16px;
  background-color: ${colors.white};
  border-radius: 4px;
  cursor: pointer;
`;

const RadioItemComponentSelected = styled(RadioItemComponent)`
  border: solid 1px ${colors.primary};
`;
