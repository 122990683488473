import styled from "styled-components";
import { colors } from "../../lib/colors";
import { fonts } from "../../styles/fonts";

type Props = {
  title: string;
  onClick: () => void;
};

export const Button: React.FC<Props> = ({ title, onClick }) => {
  return <ButtonComponent onClick={onClick}>{title}</ButtonComponent>;
};

const ButtonComponent = styled.div`
  ${fonts.title};
  color: ${colors.white};
  cursor: pointer;
  width: 280px;
  height: 48px;
  border-radius: 24px;
  line-height: 48px;
  text-align: center;
  background-color: ${colors.primary};
  margin: 32px auto;
`;
